<template>
  <div>
    <div class="mineTop" @click="routerInfo('/UserInfo')">
      <div class="flex flex-center" style="justify-content: space-between">
        <div class="flex flex-center">
          <img class="mineTop-img" :src="user.user.avatar" alt="" />
          <div class="">
            <div class="mineTop-title">{{ user.user.name }}</div>
            <!-- <div class="mine-text">XXX区XXX店 店长</div> -->
          </div>
        </div>
        <img class="mineTop-arrow" src="../assets/imgs/whileArrow.png" alt="" />
      </div>
    </div>

    <div class="other rel">
      <div class="other-title">其他功能</div>

      <div
        class="otherItem flex flex-center"
        v-for="(item, index) in list"
        :key="index"
        @click="router(item)"
      >
        <div class="flex flex-center">
          <img class="otherItem-img" :src="item.icon" alt="" />
          <div>{{ item.name }}</div>
        </div>
        <img class="otherItem-arrow" src="../assets/imgs/arrow.png" alt="" />
      </div>
    </div>

    <tabbar :current="3" :isShow="6"></tabbar>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import Tabbar from "@/components/tabbar.vue";

export default {
  components: { Tabbar },

  data() {
    return {
      list: [
        {
          icon: require("../assets/imgs/mySummary.png"),
          name: "我的小结",
          path: "/MySummary",
        },
        {
          icon: require("../assets/imgs/enterprise.png"),
          name: "我的二维码",
          path: "/MyCode",
        },
        // {
        //   icon: require("../assets/imgs/enterprise.png"),
        //   name: "企业信息",
        //   path: "",
        // },
        // {
        //   icon: require("../assets/imgs/store.png"),
        //   name: "门店信息",
        //   path: "",
        // },
      ],
      user: {
        user: {
          avatar: require("../assets/imgs/icon.png"),
          name: "",
        },
      },
    };
  },
  async mounted() {
    let res = await request._getHome({
      userid: localStorage.getItem("userid"),
    });
    console.log("首页 res", res);
    if (_.toInteger(res.code) === 1) {
      this.user = res.data;
    }
  },
  methods: {
    routerInfo(path) {
      this.$router.push(path);
    },
    router(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style scoped>
.mineTop {
  width: 100%;
  background: url(../assets/imgs/mineTop.png);
  background-size: 100% 100%;
  height: 380px;
  padding: 194px 40px 0 53px;
}

.mineTop-img {
  height: 106px;
  width: 106px;
  margin-right: 17px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.mineTop-title {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin: 0 17px 25px 0;
}

.mine-text {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.mineTop-arrow {
  width: 12px;
  height: 20px;
}

.other {
  width: 100%;
  position: relative;
  height: 1160px;
  margin-top: -40px;
  background: #ffffff;
  border-radius: 36px 36px 0px 0px;
  padding: 40px;
}

.other-title {
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #101f48;
}

.otherItem {
  padding: 52px 0 25px 0;
  width: 100%;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  border-bottom: 1px solid #ededf7;
  justify-content: space-between;
}

.otherItem-img {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

.otherItem-arrow {
  width: 12px;
  height: 20px;
}
</style>
